import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Swap Ocean',
  defaultTitle: 'Swap Ocean',
  description:
    'The most trusted decenteralized exchange platform powered by Swap Ocean ecosystem.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@SwapOceanDex',
    site: '@SwapOceanDex',
  },
  openGraph: {
    title: 'Swap Ocean - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'The most trusted decenteralized exchange platform powered by Swap Ocean ecosystem.',
    images: [{ url: 'https://swapocean.com/logo.png' }],
  },
}
